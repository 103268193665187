import React from "react";
import Carousel from "../components/Contact/Carousel";
import Conta from "../components/Contact/Conta.jsx";
import Contacts from "../components/Contact/Contact";
import Conform from "../components/Contact/ContactForm";

const Contact = () => {
  return (
    <React.Fragment>
      <div className="h-auto w-auto bg-white">
        <Carousel />
        {/* <Conta /> */}
        <Contacts />
        <Conform />
      </div>
    </React.Fragment>
  );
};

export default Contact;
