import React from "react";
import SocialPresence from "./SocialPresence";
import ContactInfo from "./ContactInfo";
import OpeningHours from "./OpeningHours";

const ContactSection = () => {
  return (
    <div className="flex flex-col bg-primary pt-10">
      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
          <SocialPresence />
        </div>
        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
          <ContactInfo />
        </div>
        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
          <OpeningHours />
        </div>
      </div>
      <div className="flex flex-col gap-2 text-golden md:gap-12 my-5 lg:gap-12 md:flex-row lg:flex-row ml-5 w-auto max-md:ml-0 max-md:w-full">
        <div className="flex-auto">Powered by PSYANN</div>
        <div className="flex-auto self-stretch">
          © 2024 Onekoo Coffee. All Rights Reserved.
          <br />
        </div>
        <div className="flex-auto">Terms of Service</div>
      </div>
    </div>
  );
};

export default ContactSection;
