// import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import { motion } from "framer-motion";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // For custom arrows

// export default function ArticleSection() {
//   const [articles, setArticles] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchArticles = async () => {
//       try {
//         const response = await axios.get(
//           "https://back.onekoocoffee.com/api/article/get-article"
//         );
//         setArticles(response.data);
//         console.table(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching Articles:", error);
//         setLoading(false);
//       }
//     };

//     fetchArticles();
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   // Custom Previous and Next arrows
//   const NextArrow = ({ onClick }) => (
//     <button
//       className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-primary text-white p-2 rounded-full z-10 hover:bg-secondary transition-colors"
//       onClick={onClick}
//     >
//       <FaArrowRight />
//     </button>
//   );

//   const PrevArrow = ({ onClick }) => (
//     <button
//       className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-primary text-white p-2 rounded-full z-10 hover:bg-secondary transition-colors"
//       onClick={onClick}
//     >
//       <FaArrowLeft />
//     </button>
//   );

//   const settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 4000, // Reduced the autoplay speed for better readability
//     pauseOnHover: true,
//     nextArrow: <NextArrow />,
//     prevArrow: <PrevArrow />,
//     appendDots: (dots) => (
//       <div className="flex justify-center mt-6">
//         <ul className="flex space-x-3">{dots}</ul>
//       </div>
//     ),
//     customPaging: (i) => (
//       <div className="w-3 h-3 bg-gray-300 rounded-full hover:bg-primary transition-all duration-300"></div>
//     ),
//   };

//   return (
//     <div className="max-w-3xl mx-auto py-12">
//       <h1 className="text-4xl font-bold text-primary text-center mb-8">
//         Our Articles
//       </h1>
//       <Slider {...settings}>
//         {articles.map((item) => (
//           <motion.div
//             key={item.id}
//             whileHover={{ scale: 1.05 }}
//             className="p-4"
//           >
//             <div className="bg-white rounded-lg shadow-lg mx-auto overflow-hidden">
//               <img
//                 src={`https://back.onekoocoffee.com/images/${item.Image}`}
//                 alt={item.Title}
//                 className="w-full h-64 object-cover"
//               />
//               <div className="p-6">
//                 <h3 className="text-3xl font-bold text-primary mb-2">
//                   {item.Title}
//                 </h3>
//                 <p className="text-primary">{item.Ldescription}</p>
//                 <Link
//                   className="mt-4 inline-block bg-secondary text-primary py-2 px-4 rounded hover:bg-primary hover:text-white transition-colors duration-300"
//                   onClick={() => window.scrollTo(0, 0)}
//                   to={`/article`}
//                 >
//                   Read More
//                 </Link>
//               </div>
//             </div>
//           </motion.div>
//         ))}
//       </Slider>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

export default function ArticleSection() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          "https://back.onekoocoffee.com/api/article/get-article"
        );
        setArticles(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Articles:", error);
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? articles.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === articles.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="max-w-3xl mx-auto py-12">
      <h1 className="text-4xl font-bold text-primary text-center mb-8">
        Our Articles
      </h1>

      <div className="relative">
        {/* Motion for the active article */}
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.5 }}
          className="p-4"
        >
          <div className="bg-white rounded-lg shadow-lg mx-auto overflow-hidden">
            <img
              src={`https://back.onekoocoffee.com/images/${articles[currentIndex].Image}`}
              alt={articles[currentIndex].Title}
              className="w-full h-64 object-cover"
            />
            <div className="p-6">
              <h3 className="text-3xl font-bold text-primary mb-2">
                {articles[currentIndex].Title}
              </h3>
              <p className="text-primary">{articles[currentIndex].Ldescription}</p>
              <Link
                className="mt-4 inline-block bg-secondary text-primary py-2 px-4 rounded hover:bg-primary hover:text-white transition-colors duration-300"
                onClick={() => window.scrollTo(0, 0)}
                to={`/article`}
              >
                Read More
              </Link>
            </div>
          </div>
        </motion.div>

        {/* Previous Button */}
        <button
          onClick={handlePrev}
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-primary text-white p-2 rounded-full z-10 hover:bg-secondary transition-colors"
        >
          <FaArrowLeft />
        </button>

        {/* Next Button */}
        <button
          onClick={handleNext}
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-primary text-white p-2 rounded-full z-10 hover:bg-secondary transition-colors"
        >
          <FaArrowRight />
        </button>
      </div>

      {/* Indicators */}
      <div className="flex justify-center mt-6 space-x-3">
        {articles.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full ${
              currentIndex === index ? "bg-primary" : "bg-gray-300"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
}
