import React from "react";
import CoffeeRoastType from "./CoffeeRoastType";
import light from "../../../assets/roast level/light-removebg-preview.png";
import medium from "../../../assets/roast level/medium-removebg-preview.png";
import mediumDark from "../../../assets/roast level/medium-dark-removebg-preview.png";
import dark from "../../../assets/roast level/dark-removebg-preview.png";
import french from "../../../assets/roast level/french_dark-removebg-preview.png";
import italian from "../../../assets/roast level/italian_dark-removebg-preview.png";
import { Link } from "react-router-dom";

const roastTypes = [
  { image: light, label: "Light Roast" },
  { image: medium, label: "Medium Roast" },
  { image: mediumDark, label: "Medium-Dark Roast" },
  { image: dark, label: "Dark Roast" },
  { image: french, label: "French Dark Roast" },
  { image: italian, label: "Italian Dark Roast" },
];

const CoffeeRoastList = () => (
  <div className="mt-12 ml-14 max-w-full w-[495px]">
    <div className="flex flex-wrap gap-5">
      {roastTypes.map((roast, index) => (
        <CoffeeRoastType key={index} image={roast.image} label={roast.label} />
      ))}
    </div>

    <button className="self-stretch px-4 pt-1.5 pb-4 mt-10 bg-yellow-900 rounded-3xl text-xl text-white">
      <Link
        onClick={() => window.scrollTo(0, 0)}
        to={"/whatwedo"}
        // className="self-stretch px-1.5 pt-1.5 pb-4 mt-[32rem] bg-secondary rounded-3xl text-xl text-white"
      >
        Discover Our Coffee Journey
      </Link>
    </button>
  </div>
);

export default CoffeeRoastList;
