// src/App.js
import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Navbar from "./components/Nav";
import Home from "./pages/Home";
import Shop from "./pages/Shop";
import Cart from "./components/Shop/Cart";
import Story from "./pages/Story";
import FarmToCup from "./pages/FarmToCup";
import Media from "./pages/Media";
import Article from "./components/Media/Article/ArticleDetail";
import Contact from "./pages/Contact";
import Footer from "./components/Footer/Footer";
import Checkout from "./components/Shop/Checkout";
import Account from "./pages/Account";
import SignUp from "./components/Login/SignUp";
import AdminPage from "./pages/AdminPage";
import Adminlogin from "./pages/Adminlogin";
import UserProfile from "./pages/UserProfile";
import Establishment from "./components/Story/Establishment"
import WhatWeDo from "./components/Story/WhatWeDo";
import Relationship from "./components/Relationship";
import Strength from "./components/Strength";
import Menu from "./components/Menu";
import FrontShop from "./components/frontShop/CoffeeShop";
import './App.css';

function App() {
  const location = useLocation();

  return (
    <div className="App font-Garamond bg-white">
      <Navbar />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/home"} element={<Home />} />
        <Route path={"/shop"} element={<FrontShop />} />
        <Route path={"/pre-shop"} element={<Shop />} />
        <Route path={"/cart"} element={<Cart />} />
        <Route path={"/our-story"} element={<Story />} />
        <Route path={"/establishment"} element={<Establishment />} />
        <Route path={"/whatwedo"} element={<WhatWeDo />} />
        <Route path={"/relationship"} element={<Relationship />} />
        <Route path={"/strength"} element={<Strength />} />
        <Route path={"/menu"} element={<Menu />} />
        <Route path={"/farm-to-cup"} element={<FarmToCup />} />
        <Route path={"/media"} element={<Media />} />
        <Route path={"/article"} element={<Article />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/checkout"} element={<Checkout />} />
        <Route path={"/account"} element={<Account />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path={"/profile"} element={<UserProfile />} />
        <Route path="/admin-login" element={<Adminlogin />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
