import React from "react";
import { Link } from "react-router-dom";

function DescriptionSection() {
  return (
    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col self-stretch my-auto text-xl max-md:mt-10 max-md:max-w-full">
        <p className="text-start max-md:max-w-full text-xl text-primary">
          Description: At Onekoo Coffee, our supply chain starts with coffee
          cultivation in Ethiopia's renowned regions—Sidamo, Yirgacheffe, and
          Harrar—by smallholder farmers supported by the Oromia Coffee Farmers'
          Cooperative Union (OCFCU). After harvesting, the coffee cherries are
          processed to remove the pulp and skin, ensuring high-quality beans.
          These beans are then expertly roasted to highlight their distinctive
          flavors and aromas. The roasted coffee is carefully packaged in
          various sizes to preserve its quality for both individual consumers
          and wholesale buyers. Finally, our premium coffee is brewed into a
          range of exquisite beverages, including espressos, lattes, and
          traditional coffee, delivering a rich and immersive experience from
          farm to cup.
        </p>
        <button className="self-end px-14 pt-1.5 pb-4 mt-16 mr-20 max-w-full text-white rounded-3xl bg-yellow-950 w-auto max-md:px-5 max-md:mt-10 max-md:mr-2.5">
          <Link onClick={() => window.scrollTo(0, 0)} to={"/farm-to-cup"}>
            Read more about each step
          </Link>
        </button>
      </div>
    </div>
  );
}
export default DescriptionSection;
