import React from "react";
import ProductDescription from "./ProductDescription";
import SectionTitle from "./SectionTitle";
import CoffeeImage from "./CoffeeImage";

function CoffeeProducts() {
  const descriptions0 = [
    {
      text: "ONEKOO COFFEE where passion meets perfection in every cup. Discover our premium selection of roasted and ground coffee, expertly crafted to suit every taste. From light and medium roasts to rich French and Italian dark roasts, our diverse offerings ensure there's a flavor for every coffee lover. Each blend is carefully packaged to lock in freshness, available in convenient sizes to meet your needs.",
      highlight: false,
    },
  ];
  const descriptions1 = [
    {
      text: "At our inviting cafés in Addis Ababa, including the Ethiopian Airport and Addis Ababa International Airport, immerse yourself in a world of exceptional coffee drinks. Indulge in bittersweet espressos, traditionally cupped coffee, frothy macchiatos, and creamy lattes—all crafted to perfection. Proudly partnered with the Oromia Coffee Farmers' Cooperative Union, Onekoo Coffee is expanding its reach to bring our exquisite flavors to coffee enthusiasts everywhere.",
      highlight: false,
    },
  ];

  return (
    // <main className=" pt-20 pb-7 bg-secondary text-center max-md:px-5 max-md:pb-24">
    <div className="px-8 py-12 flex gap-5 max-md:flex-col bg-secondary">
      <section className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full">
        <div className="flex flex-col grow text-center items-start text-white max-md:mt-10 max-md:max-w-full">
          <SectionTitle
            mainTitle="Our Coffee Products"
            subTitle="Taste Our Story"
          />
          {descriptions0.map((desc, index) => (
            <ProductDescription
              key={index}
              text={desc.text}
              highlight={desc.highlight}
            />
          ))}
          <SectionTitle
            subTitle="Experience our coffee first hand"
            className="mt-16 ml-20 text-3xl text-primary max-md:mt-10 max-md:max-w-full"
          />
          {descriptions1.map((desc, index) => (
            <ProductDescription
              key={index}
              text={desc.text}
              highlight={desc.highlight}
            />
          ))}
          <SectionTitle
            subTitle="Enjoy a taste of excellence with Onekoo Coffee"
            className="mt-16 text-3xl text-primary max-md:mt-10 max-md:max-w-full"
          />
        </div>
      </section>
      <CoffeeImage />
    </div>
    // </main>
  );
}

export default CoffeeProducts;
