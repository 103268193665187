import React from "react";
import CoffeeRoastList from "./CoffeeRoastList";
import images from "../../../assets/images/roasting.jpg";

const WhatWeDo = () => {
  return (
    <section className="flex overflow-hidden flex-col items-center pt-7 bg-secondary">
      <h1 className="text-4xl text-white font-bold">What We Do</h1>
      <p className="mt-2 py-5 italic text-2xl text-white">
        Bringing Exceptional Coffee to You
      </p>
      <div className="self-stretch mt-5 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-[31%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={images}
              alt="Coffee illustration"
              className="object-cover grow w-full aspect-[0.62] md:aspect-[3.62] lg:aspect-[3.62] max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col ml-5 w-[69%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-start px-14 py-16 mx-auto w-full bg-secondary max-md:px-5 max-md:max-w-full">
              <p className="self-stretch text-xl text-white max-md:max-w-full">
                At Onekoo Coffee, we are passionate about delivering premium
                roasted and ground coffee that caters to every taste preference.
                Our diverse range includes:
              </p>
              <CoffeeRoastList />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
