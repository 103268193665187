import React from "react";

const CoffeeRoastType = ({ image, label }) => {
  console.log(image, label); // Debugging to ensure props are passed
  return (
    <div className="flex flex-col mt-10">
      <img src={image} className="flex shrink-0 h-36 w-36" alt={label} />
      <div className="mt-14 md:mt-10 text-xl text-white">{label}</div>
    </div>
  );
};

export default CoffeeRoastType;
