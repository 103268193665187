import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import PremiumPackaging from "./PremiumPackaging/PremiumPackaging";
import Cafes from "./cafes/CafeLocations";
import light from "../../assets/roast level/light-removebg-preview.png";
import medium from "../../assets/roast level/medium-removebg-preview.png";
import mediumDark from "../../assets/roast level/medium-dark-removebg-preview.png";
import dark from "../../assets/roast level/dark-removebg-preview.png";
import french from "../../assets/roast level/french_dark-removebg-preview.png";
import italian from "../../assets/roast level/italian_dark-removebg-preview.png";
import roastMachine from "../../assets/images/roasting.jpg";

const roastTypes = [
  { image: light, label: "Light Roast" },
  { image: medium, label: "Medium Roast" },
  { image: mediumDark, label: "Medium-Dark Roast" },
  { image: dark, label: "Dark Roast" },
  { image: french, label: "French Dark Roast" },
  { image: italian, label: "Italian Dark Roast" },
];

const CoffeeRoastType = ({ image, label }) => {
  console.log(image, label); // Debugging to ensure props are passed
  return (
    <div className="flex flex-col mt-10">
      <img src={image} className="flex shrink-0 h-36 w-36" alt={label} />
      <div className="mt-14 md:mt-10 text-xl text-primary">{label}</div>
    </div>
  );
};


const CoffeeRoastList = () => (
  <div className="mt-12 ml-14 max-w-full w-[495px]">
    <div className="flex flex-wrap gap-5">
      {roastTypes.map((roast, index) => (
        <CoffeeRoastType key={index} image={roast.image} label={roast.label} />
      ))}
    </div>
  </div>
);

const WeDo = () => {
  return (
    <section className="flex overflow-hidden flex-col items-center pt-60 mt-[-10rem] bg-white">
      <h1 className="text-4xl text-primary font-bold">What We Do</h1>
      <p className="mt-2 text-2xl italic pt-5 text-golden">
        Bringing Exceptional Coffee to You
      </p>
      <div className="self-stretch mt-5 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-[31%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={roastMachine}
              alt="Coffee illustration"
              className="object-contain grow w-full aspect-[0.62] md:aspect-[3.62] lg:aspect-[3.62] max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col ml-5 w-[69%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-start px-14 py-16 mx-auto w-full bg-white max-md:px-5 max-md:max-w-full">
              <p className="self-stretch text-xl text-primary max-md:max-w-full">
                At Onekoo Coffee, we are passionate about delivering premium
                roasted and ground coffee that caters to every taste preference.
                Our diverse range includes:
              </p>
              <CoffeeRoastList />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const WhatWeDo = () => {
  return (
    <React.Fragment>
      <div className="pt-28 bg-white">
        <WeDo />
        <PremiumPackaging />
        <Cafes />
        <p className="text-start px-6 text-primary md:px-60 lg:px-60 pb-12">
          Our commitment is to deliver the finest coffee experience through both
          our premium packaging and exceptional café services. With each cup of
          Onekoo Coffee, you’re savoring not just a drink, but a tradition of
          quality and dedication.
        </p>
      </div>
    </React.Fragment>
  );
};

export default WhatWeDo;
