import React from "react";

const OpeningHours = () => {
  const hours = [
    { day: "Mon - Fri", time: "08:00-02:00" },
    { day: "Thursday", time: "08:00-02:00" },
    { day: "Sunday", time: "08:00-02:00" },
  ];

  return (
    <section className="flex grow gap-5 mt-2 text-white max-md:mt-10">
      {/* <div className="shrink-0 w-1 border border-black border-solid h-[377px]" /> */}
      <div className="flex flex-col grow shrink-0 self-start basis-0 w-fit mx-10">
        <h2 className="text-2xl mb-3">Opening Hours</h2>
        {hours.map((schedule, index) => (
          <React.Fragment key={index}>
            <div className="flex gap-5 justify-between mt-4 ml-4 max-w-full text-sm whitespace-nowrap w-[289px] max-md:ml-2.5">
              <div>{schedule.day}</div>
              <div>{schedule.time}</div>
            </div>
            {index < hours.length - 1 && (
              <div className="shrink-0 mt-6 bg-white border border-black border-solid h-[3px]" />
            )}
          </React.Fragment>
        ))}
      </div>
    </section>
  );
};

export default OpeningHours;
