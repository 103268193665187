import React from 'react';
import ProductCard from './ProductCard';

function ProductSection({ title, products }) {
  return (
    <section className="mt-14 w-full max-md:mt-10">
      <h3 className="text-4xl font-bold text-center text-primary">{title}</h3>
      <div className="self-stretch mt-16 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          {products.map((product, index) => (
            <ProductCard key={index} image={product.image} weight={product.weight} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProductSection;