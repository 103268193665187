import React, { useState } from "react";
import { motion, useInView } from "framer-motion";
import natural from "../../assets/one koo icon collection/TRADITIONAL_ROASTING_ONEKOO_PSYANN_GRAPHICS.png";
import ceremony from "../../assets/onekoo coffee illustrations/illustration_onekoo_coffe cermony_2024 psyann graphics.png"
import { Link } from "react-router-dom";

const ExpandableParagraph = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  // Ref and animation control for the text section
  const textRef = React.useRef(null);
  const textInView = useInView(textRef, { once: true });

  // Ref and animation control for the images
  const imageRef = React.useRef(null);
  const imageInView = useInView(imageRef, { once: true });

  return (
    <React.Fragment>
      <motion.div
        className="max-w-[100%] mx-0 md:mx-10 lg:mx-10 my-8 p-4 border-primary rounded-lg text-primary shadow-md"
        initial={{ opacity: 0 }}
        animate={{ opacity: textInView ? 1 : 0 }}
        transition={{ duration: 1 }}
        ref={textRef}
      >
        <h4 className="flex flex-col text-center mb-3 py-4 text-4xl font-bold font-Garamond px-16 md:px-[30rem] lg:px-[30rem] text-primary">
        <img src={natural} className="w-16 h-16 mx-auto" alt="" />
          Every Bean has a story to tell!
        </h4>
        <motion.p
          initial={{ height: "auto" }}
          animate={{ height: isExpanded ? "auto" : "5.5rem" }}
          className={`overflow-hidden text-xl px-1 md:px-12 lg:px-12 text-start transition-all duration-500 ${
            isExpanded ? "h-auto" : "h-24"
          }`}
        >
          Onekoo Coffee is a company deeply rooted in Ethiopian coffee culture,
          committed to providing the finest quality coffee while fostering
          sustainable development and enhancing the lives of coffee farmers and
          communities. The company offers a wide array of premium coffee
          beverages crafted from the finest Ethiopian Arabica beans,
          complemented by a variety of freshly prepared food options. Onekoo
          Coffee aims to create a unique and immersive coffee experience,
          reflecting Ethiopian culture and heritage, while also promoting fair
          trade practices and community development initiatives. The company's
          vision is to be a global leader in the coffee industry, renowned for
          its ethical sourcing, superior coffee products, and positive social
          impact.
        </motion.p>
        <button
          onClick={toggleExpansion}
          className="mt-4 text-white hover:text-primary bg-secondary hover:bg-white rounded-xl p-2 focus:outline-none"
        >
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      </motion.div>

      <div className="max-w-auto ml-auto md:ml-20 lg:ml-32 mb-11 mt-28 flex">
        <div className="flex flex-col md:flex-row gap-5 p-4">
          <div className="flex flex-col w-full md:w-[57%]">
            <motion.div
              className="flex flex-col px-5 text-primary mt-10 md:mt-0"
              initial={{ opacity: 0, x: -50 }}
              animate={{
                opacity: imageInView ? 1 : 0,
                x: imageInView ? 0 : -50,
              }}
              transition={{ duration: 1 }}
              ref={imageRef}
            >
              <div className="text-4xl font-semibold">
                History and Establishment
              </div>
              <div className="text-2xl italic py-5 text-golden">
                From Coffee Roots to Global Cup
              </div>
              <div className="mt-8 text-start text-xl">
                <p>
                  The journey of coffee begins in Oromia, Ethiopia—the
                  birthplace of this cherished beverage. For the Oromo people,
                  coffee is more than a drink; it’s integral to trade, spiritual
                  life, and community cohesion. Despite its cultural
                  significance, Oromia faces challenges such as low market
                  prices and climate change, which impact the livelihoods of
                  smallholder farmers who cultivate 65% of Ethiopia’s coffee.
                </p>
                <br />
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={"/establishment"}
                  className="mt-[100rem] text-white hover:text-primary bg-secondary hover:bg-white rounded-xl p-2 focus:outline-none"
                >
                  Read More
                </Link>
              </div>
            </motion.div>
          </div>
          <motion.div
            className="flex flex-col w-full md:w-[43%]"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: imageInView ? 1 : 0, x: imageInView ? 0 : 50 }}
            transition={{ duration: 1 }}
            ref={imageRef}
          >
            <div className="max-w-full">
              <div className="flex flex-col md:flex-row">
                <div className="flex flex-col w-full md:w-6/12">
                  <img
                    loading="lazy"
                    alt="hb"
                    src={ceremony}
                    className="self-stretch object-contain my-auto w-full aspect-[1.1] rounded-xl z-10"
                  />
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExpandableParagraph;
