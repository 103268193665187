import React from 'react';
import ShopCard from './ShopCard';
import ProductSection from './ProductSection';
import ProductCard from './ProductCard';
import ImageCard from './ImageCard';
import bole from "../../assets/images/boleBranch.jpg";
import global from "../../assets/images/global.jpg";
import ldeta from "../../assets/images/ldeta.jpg";
import airport from "../../assets/images/airport.jpg";
import hot from "../../assets/onekoo packaging/standard_onekoo_coffee_cup_psyann_graphics_2024_.png";
import dabo from "../../assets/images/dabo.png";
import kolo from "../../assets/images/kolo.png";
import ground from "../../assets/onekoo packaging/standard_onekoo_coffee_packaging_psyann_graphics_2024_png.png";
import roast from "../../assets/onekoo packaging/premium_onekoo_coffee_packaging_psyann_graphics_2024_png.png";
import { Link } from 'react-router-dom';

const shopData = [
  {
    image: global,
    name: "Global Branch",
    location: "Located at Mikwer Plaza",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "globalbranch@onekoocoffee.com"
  },
  {
    image: bole,
    name: "Bole Branch",
    location: "Located at Africa Development Bank building",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "bolebranch@onekoocoffee.com"
  },
  {
    image: airport,
    name: "Airport branch",
    location: "Located at Addis Ababa Airport",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "airportbanch@onekoocoffee.com"
  },
  {
    image: ldeta,
    name: "Lideta Branch",
    location: "Located at Awash Bank building",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "ldetabranch@onekoocoffee.com"
  }
];

const groundedProducts = [
  { image: ground, weight: "2 Kg" },
  { image: ground, weight: "1 Kg" },
  { image: ground, weight: "500 gm" },
  { image: ground, weight: "250 gm" }
];

const roastedProducts = [
  { image: roast, weight: "2 Kg" },
  { image: roast, weight: "1 Kg" },
  { image: roast, weight: "500 gm" },
  { image: roast, weight: "250 gm" }
];

const otherProducts = [
  { name: "Hot Chocolate", image: hot },
  { name: "Cookie", image: dabo },
  { name: "Kolo", image: kolo }
];

function CoffeeShop() {
  return (
    <main className="flex overflow-hidden flex-col items-center px-10 pt-60 pb-36 bg-white h-auto max-md:px-5 max-md:pb-24">
      <h1 className="text-4xl font-bold text-center text-yellow-950">
        ONEKOO COFFEE Shops
      </h1>
      <section className="mt-24 w-full max-w-[1211px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          {shopData.slice(0, 2).map((shop, index) => (
            <ShopCard key={index} {...shop} />
          ))}
        </div>
      </section>
      <section className="mt-24 w-full max-w-[1211px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          {shopData.slice(2).map((shop, index) => (
            <ShopCard key={index} {...shop} />
          ))}
        </div>
      </section>
      <h2 className="mt-11 text-4xl font-bold text-center text-primary max-md:mt-10">
        ONEKOO COFFEE Products
      </h2>
      <ProductSection title="Grounded Product's" products={groundedProducts} />
      <ProductSection title="Roasted Product's" products={roastedProducts} />
      <section className="mt-32 w-full max-w-[1120px] max-md:mt-10 max-md:max-w-full">
      <h1 className='text-4xl text-primary font-bold py-12'>Special Products</h1>
        <div className="flex gap-5 max-md:flex-col">
          {otherProducts.map((product, index) => (
            <div key={index} className="flex flex-col items-center p-4 border-secondary rounded-lg shadow-md shadow-secondary">
              {/* Image */}
              <ImageCard {...product} />
              
              {/* Name */}
              <h2 className="mt-4 text-xl text-primary font-semibold">{product.name}</h2>
              
              {/* Button */}
              <button className="mt-4 px-4 py-2 bg-golden text-white rounded-lg hover:bg-primary hover:text-golden">
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={`/pre-shop`}
                >
                  Buy Now
                </Link>
              </button>
            </div>
          ))}
        </div>
      </section>

    </main>
  );
}

export default CoffeeShop;